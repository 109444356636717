$mol_nav $mol_plugin
	cycle? false
	-
	mod_ctrl false
	mod_shift false
	mod_alt false
	-
	keys_x? /
	keys_y? /
	-
	current_x? null
	current_y? null
	-
	event_up?event null
	event_down?event null
	event_left?event null
	event_right?event null
	event *
		^
		keydown?event <=> event_key?event null
