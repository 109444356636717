$mol_search $mol_pop
	query? \
	suggests /string
	plugins /$mol_plugin
		^
		<= Hotkey $mol_hotkey
			key * escape? <=> clear? null
		<= Nav $mol_nav
			keys_y <= nav_components /$mol_view
			current_y?component <=> nav_focused?component null
	showed? <=> suggests_showed? false
	align_hor \right
	Anchor $mol_view
		sub <= anchor_content /
			<= Query $mol_string
				value? <=> query? \
				hint <= hint @ \Search...
				submit?event <=> submit?event null
				enabled <= enabled true
				keyboard <= keyboard \search
				enter <= enter \search
				bring => bring
			<= Clear $mol_button_minor
				hint @ \Clear
				click?event <=> clear?event null
				sub /
					<= Clear_icon $mol_icon_cross
	bubble_content /$mol_view_content
		<= Menu $mol_list
			rows <= menu_items /$mol_view
	Suggest* $mol_button_minor
		click?event <=> suggest_select*?event null
		sub <= suggest_content* /$mol_view_content
			<= Suggest_label* $mol_dimmer
				haystack <= suggest_label* \
				needle <= query? \
