$mol_pop $mol_view
	showed? false
	align_vert \
	align_hor \
	prefer \vert
	sub /
		<= Anchor null
	sub_visible /
		<= Anchor
		<= Bubble $mol_pop_bubble
			align <= align \bottom_center
			content <= bubble_content /$mol_view_content
			height_max <= height_max 9999

$mol_pop_bubble $mol_view
	sub <= content /$mol_view_content
	style *
		^
		maxHeight <= height_max 9999
	attr *
		^
		mol_pop_align <= align \
		tabindex 0
